#signature_div {
  width: 65%;
  float: right;
}
@media (max-width: 1250px) {
}
@media (max-width: 1238px) {
}

@media (max-width: 1199px) {
}
@media (max-width: 1120px) {
}
@media (max-width: 1084px) {
}
@media (max-width: 1066px) {
}
@media (max-width: 1046px) {
}
@media (max-width: 991px) {
}
@media (max-width: 958px) {
}
@media (max-width: 868px) {
}
@media (max-width: 768px) {
  #signature_div {
    width: 100%;
  }
}
@media (max-width: 612px) {
  #signature_div {
    width: 100%;
  }
}
@media (max-width: 577px) {
  #signature_div {
    width: 100%;
  }
}
@media (max-width: 531px) {
  #signature_div {
    width: 100%;
  }
}
@media (max-width: 462px) {
  #signature_div {
    width: 100%;
  }
}
@media (max-width: 360px) {
  #signature_div {
    width: 100%;
  }
}

.tab-agreement_content ol,
.tab-agreement_content ul,
.tab-agreement_content li {
  margin-left: 20px !important;
}

.tab-agreement_content>.tab-pane.active>div>ol,
.tab-agreement_content>.tab-pane.active>div>ul {
  list-style: inherit !important;
}

.agreement_input_textfield,
.agreement_input_textarea,
.agreement_input_checkbox,
.agreement_input_radio,
.agreement_input_dropdown {
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  padding: 8px;
  font-size: 14px;
  outline: none;
  margin-bottom: 10px;
}

.agreement_input_checkbox,
.agreement_input_radio {
  margin-right: 5px;
}

.agreement_input_dropdown {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="%23333" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position: right 8px top 50%;
  background-size: 18px;
  padding-right: 30px;
}

.agreement_input_dropdown option {
  font-size: 14px;
}
